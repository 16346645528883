import axios from "axios";
import { addAxiosDefaultHeaders, removeDefaultHeaders } from "../../helpers";
export class InsuranceService {

    constructor(_baseUrl) {
        this._baseUrl = `${_baseUrl}/insurance/v1/qe`
    }
    getPolicies(values) {
        return axios.post(`${this._baseUrl}/policy-types/types`, values);
    }
    loadComparison(params) {
        return axios.get(`${this._baseUrl}/policies/comparison`, { params });
    }
    getObjectives() {
        return axios.get(`${this._baseUrl}/util/objectives`);
    }
    loadAll() {
        return axios.get(`${process.env.REACT_APP_API_URL}/quotation/pv1/helper/choices`);
    }
    getSmeMedicalAges() {
        return axios.get(`${this._baseUrl}/policies/corp-medical-ages`);
    }
    getIP() {
        removeDefaultHeaders(axios)
        const result = axios.get(`https://ipgeolocation.abstractapi.com/v1/?api_key=${process.env.REACT_APP_ABSTRACT_API_KEY}`)
        addAxiosDefaultHeaders(axios)
        return result;
    }
}
