import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { Button, TextField } from '../../../components/ui';
import { phoneNumberValidationSchema } from '../schema';
import { LocalStorageService, MixpanelService, localStorageKeys } from '../../../application/services';

export function PhoneNumberField(props) {
    const { t } = useTranslation();
    const insuranceTypeId = LocalStorageService.get(localStorageKeys.insuranceTypeID)
    const formik = useFormik({
        initialValues: {
            phoneNumber: LocalStorageService.get(localStorageKeys.userPhoneNumber) || '',
        },
        validationSchema: phoneNumberValidationSchema,
        onSubmit: (values) => {
            props.handleClickNext()
            LocalStorageService.set(localStorageKeys.userPhoneNumber, values.phoneNumber);
            MixpanelService.getInstance().phoneNumberFieldClickNext(insuranceTypeId, { phone_number: values.phoneNumber })
            if (window.dataLayer) {
                window.dataLayer.push({
                    event: 'customerPhoneNumber',
                    customerPhoneNumber: values.phoneNumber,
                });
            }
        },
    });

    return (
        <div>
            <div className="heading mt-45">
                <p>{props.title ? props.title : t("can_you_tell_me")} </p>
                <h3>{t("ask_for_phone")}</h3>
            </div>
            <div className="mt-30">
                <form onSubmit={formik.handleSubmit} autoComplete="off" >
                    <TextField id="phoneNumber"
                        placeholder={t("phone_placeholder")}
                        name="phoneNumber"
                        type="tel"
                        value={formik.values.phoneNumber}
                        onFocus={() => MixpanelService.getInstance().clickPhoneNumberField(insuranceTypeId)}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.phoneNumber &&
                            Boolean(formik.errors.phoneNumber)
                        }
                        helperText={
                            formik.touched.phoneNumber &&
                            formik.errors.phoneNumber
                        }
                    />

                    <div className="btn-wrapper">
                        <Button
                            className={`btn ${!formik.values.phoneNumber ? "disabled" : "active"}`}
                            title={t("next")}
                            disabled={!formik.values.phoneNumber}
                            type="submit"
                        />
                    </div>


                </form>
            </div>
        </div >
    )
}
