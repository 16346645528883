import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Stepper, withErrorHandler, Greeting } from '../../../components'
import { Error } from '../../../components/application';
import { LifeObjectiveField, GenderField, InsuranceProviders, EmployeesNumberField, CarBrandField } from '../components';
import { insuranceTypeEnum, policySteps } from '../../../config/enums';
import { Governorates } from '../components';
import TravelDate, { CustomDOB } from '../components/date';
import { MixpanelService } from '../../../application/services';
function StepTwo(props) {
    const { t } = useTranslation();
    const activeStep = 2
    const [invalidRoute, setsetInvalidRoute] = useState(null)
    const [insuranceType, setInsuranceType] = useState(null)

    useEffect(() => {
        if (props.location.state?.insurance_type !== props.match.params?.type) {
            setsetInvalidRoute(true)
        }
        else {
            setInsuranceType(props.location.state?.insurance_type)
            MixpanelService.getInstance().qeStepTwoViewed(props.location.state?.insurance_type)
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps
    const handleClickNext = () => {
        props.history.push({ pathname: `/${props.location.state?.insurance_type}/step-three`, state: { insurance_type: props.location.state?.insurance_type, prev_path: props.location.pathname }, search: props.location.search })
    }
    return (
        <div className="container" id="page-wrapper">
            {!navigator.onLine ?
                <Error message={t("connection_error_message")} btnText={t("try_again_text")} onClick={() => window.location.reload()} /> :
                invalidRoute ? <Error message={t("invalid_path_message")} btnText={t("home_btn")} autoRedirect /> :
                    <div className="step pl-15 pr-15 wrapper">
                        <div className="head-wrapper">
                            <div>
                                <Greeting />
                                <Stepper activeStep={activeStep} numOfSteps={policySteps[insuranceType]} />
                            </div>
                        </div>
                        {insuranceType === insuranceTypeEnum.Life ? <LifeObjectiveField handleClickNext={handleClickNext} /> :
                            insuranceType === insuranceTypeEnum.Medical ? <GenderField handleClickNext={handleClickNext} /> :
                                insuranceType === insuranceTypeEnum.Motor ? <CarBrandField handleClickNext={handleClickNext} /> :
                                    insuranceType === insuranceTypeEnum.Travel ? <TravelDate handleClickNext={handleClickNext} /> :
                                        insuranceType === insuranceTypeEnum.Home ? <Governorates handleClickNext={handleClickNext} /> :
                                            insuranceType === insuranceTypeEnum.SMEMedical ? <EmployeesNumberField handleClickNext={handleClickNext} /> :
                                                insuranceType === insuranceTypeEnum.InternationalMedical ? <CustomDOB handleClickNext={handleClickNext} /> :
                                                    undefined}
                        <InsuranceProviders type={props.match.params?.type} />
                    </div>
            }
        </div>
    )
}
export default withErrorHandler(StepTwo, axios)
