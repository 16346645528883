import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Button, TextField } from '../../../components/ui';
import { LocalStorageService, localStorageKeys, MixpanelService } from "../../../application/services";
import { validationRules } from "../../../config/validationRules";
import { convertArabic2EnNumbers } from '../../../helpers'

const PRICE_MIN_VALUE = validationRules.priceMinValue;
const PRICE_MAX_VALUE = validationRules.priceMaxValue;
export function CarPriceField(props) {
    const { t } = useTranslation();
    const [price, setPrice] = useState(LocalStorageService.get(localStorageKeys.motorPrice) || '')
    const [error, setError] = useState(undefined)
    const replaceNonNumeric = (numStr) => {
        return String(numStr).replace(/[^0-9\u0660-\u0669]/g, '')
    }
    const commarize = (numStr) => {
        return replaceNonNumeric(numStr).replace(/.(?=(...)*...$)/g, '$&,')
    }
    const handleChange = (e) => {
        const value = e.target.value
        var priceValue = commarize(value)
        setPrice(priceValue)
        const priceValueToNumber = priceValue.replace(/,/g, "")
        if (isNaN(Number(convertArabic2EnNumbers(priceValueToNumber)))) {
            setError({ ...error, price_invalid: t("validation_price_value_message") })
        }
        else if (convertArabic2EnNumbers(priceValueToNumber) < PRICE_MIN_VALUE) {
            setError({ ...error, price_invalid: t("validation_price_value_message") })
        }
        else if (convertArabic2EnNumbers(priceValueToNumber) > PRICE_MAX_VALUE) {

            setError({ ...error, price_invalid: t("validation_price_value_message") })

        }
        else if (!convertArabic2EnNumbers(priceValueToNumber) > 0) {

            setError({ ...error, price_invalid: t("validation_price_value_message") })
        }
        else {
            setError(undefined)
        }
    }
    const handleNextStep = (e) => {
        e.preventDefault()
        if (!error) {
            price && props.handleClickNext()
            LocalStorageService.set(localStorageKeys.motorPrice, price)
            MixpanelService.getInstance().carPriceFieldClickNext({ car_price: price })

        }
    }
    return (
        <div>
            <div className="heading mt-45">
                <p>{t("can_you_tell_me")} </p>
                <h3>{t("car_price_label")}</h3>
            </div>
            <div className="mt-30">
                <form onSubmit={handleNextStep} autoComplete="off" >
                    <TextField id="price"
                        placeholder={t("car_price_placeholder")}
                        value={price}
                        onChange={handleChange}
                        error={error && error.price_invalid ? true : false}
                        helperText={error && error.price_invalid}
                        name="price"
                        onFocus={() => MixpanelService.getInstance().clickCarPriceField()}

                    />
                    <div className="btn-wrapper">
                        <Button
                            className={`btn ${!price || error ? "disabled" : "active"}`}
                            title={t("next")}
                            type="submit"
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}
