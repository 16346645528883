import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import Amenli from './pages/subscription'
import Layout from './pages/layout';
import InsuranceType from './pages/quotationEngine/insuranceType';
import StepOne from './pages/quotationEngine/stepOne';
import StepTwo from './pages/quotationEngine/stepTwo';
import StepThree from './pages/quotationEngine/stepThree';
import StepFour from './pages/quotationEngine/stepFour';
import StepFive from './pages/quotationEngine/stepFive';
import StepSix from './pages/quotationEngine/stepSix';
import StepSeven from './pages/quotationEngine/stepSeven';
import StepEight from './pages/quotationEngine/stepEight';
import Packages from './pages/quotationEngine/results';
import { withTranslation } from "react-i18next";
import { ThankYouPage } from './components';
import Checkout from './pages/checkout';
import PurchaseLink from './pages/purchaseLink';
import { KioskCode, IFrame, PostPay } from './pages/checkout/components';
import { Success, PaymentFailed, Error } from './components/application';
import { getCurrentLanguage } from './config/i18n';
import { languageEnum } from './config/enums';
import { AppLink } from './pages/appLink';
import PartnerLeadForm from './pages/partnerLeadForm';
import DeliveryLinks from './pages/deliveryLink';
class Routes extends Component {
    state = {
        mobileView: undefined
    }
    selectedLang = getCurrentLanguage();
    componentDidMount() {
        window.addEventListener("resize", this.resize());
        const selectedLang = getCurrentLanguage();
        if (selectedLang === languageEnum.Arabic) {
            document.documentElement.dir = "rtl"
        }
        else {
            document.documentElement.dir = "ltr"
        }
    }
    resize = () => {
        if (window.innerWidth <= 767) {
            this.setState({ mobileView: true })
        }
        else {
            this.setState({ mobileView: false })
        }
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.resize());
    }
    ErrorPage = () => (
        <div className="container" id="page-wrapper">
            <div className="wrapper">
                <Error message={this.props.t("invalid_path_message")} btnText={this.props.t("home_btn")} autoRedirect />
            </div>
        </div>
    );
    render() {
        const { mobileView } = this.state;
        return (
            <>
                {mobileView !== undefined &&
                    <Router>
                        <Layout>
                            {/* <Amenli /> */}
                            <Switch>
                                <Route exact path={["/", "/en", "/ar"]} component={InsuranceType} />
                                <Route exact path="/:type/step-one" component={StepOne} />
                                <Route path="/:type/step-two" component={StepTwo} />
                                <Route path="/:type/step-three" component={StepThree} />
                                <Route path="/:type/step-four" component={StepFour} />
                                <Route path="/:type/step-five" component={StepFive} />
                                <Route path="/:type/step-six" component={StepSix} />
                                <Route path="/:type/step-seven" component={StepSeven} />
                                <Route path="/:type/step-eight" component={StepEight} />
                                <Route path="/:type/loading-packages" component={Packages} />
                                <Route path="/checkout" component={Checkout} />
                                <Route path="/success" component={Success} />
                                <Route path="/post_pay" component={PostPay} />
                                <Route path="/payment-failed" component={PaymentFailed} />
                                <Route path="/kiosk-code" component={KioskCode} />
                                <Route path="/buy-policy" component={IFrame} />
                                <Route path="/purchase/pay" component={PurchaseLink} />
                                <Route path="/thank-you" component={ThankYouPage} />
                                <Route path="/app" component={AppLink} />
                                <Route path="/forms/:form_uuid" component={PartnerLeadForm} />
                                <Route path="/delivery-links" component={DeliveryLinks} />
                                <Route component={this.ErrorPage} />
                            </Switch>
                        </Layout>
                    </Router>
                }
            </>
        )
    }
}
export default withTranslation()(Routes)