import React from 'react'

export function UIButton({
    title,
    disabled,
    loading,
    onClick,
    className,
    type
}) {
    return (
        <button
        disabled={disabled || loading}
        onClick={onClick}
        className={className}
        type={type}
        >
            {loading ? "Loading..." : title}
        </button>
    )
}
