import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Stepper, Error, Greeting } from '../../../components';
import { PhoneNumberField, HomeValue, ContentValue, NameField, InsuranceProviders, CarYearField } from '../components';
import { homeTypeEnum, insuranceTypeEnum, policySteps } from '../../../config/enums';
import { LocalStorageService, localStorageKeys, MixpanelService } from '../../../application/services';

export default function StepFour(props) {
    const { t } = useTranslation();
    const activeStep = 4
    const [invalidRoute, setsetInvalidRoute] = useState(null)
    const [insuranceType, setInsuranceType] = useState(null)
    const ownershipType = LocalStorageService.get(localStorageKeys.homeType)

    useEffect(() => {
        if (props.location.state?.insurance_type !== props.match.params?.type) {
            setsetInvalidRoute(true)
        }
        else {
            setInsuranceType(props.location.state?.insurance_type)
            MixpanelService.getInstance().qeStepFourViewed(props.location.state?.insurance_type)
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps
    const handleClickNext = () => {
        if (insuranceType && (insuranceType === insuranceTypeEnum.Life || insuranceType === insuranceTypeEnum.Medical)) {
            props.history.push({ pathname: `/${props.location.state?.insurance_type}/loading-packages`, state: { insurance_type: props.location.state.insurance_type }, search: props.location.search })
        }
        else {
            props.history.push({ pathname: `/${props.location.state?.insurance_type}/step-five`, state: { insurance_type: props.location.state.insurance_type }, search: props.location.search })
        }
    }
    return (
        <div className="container" id="page-wrapper">
            {!navigator.onLine ?
                <Error message={t("connection_error_message")} btnText={t("try_again_text")} onClick={() => window.location.reload()} /> :
                invalidRoute ? <Error message={t("invalid_path_message")} btnText={t("home_btn")} autoRedirect /> :
                    <div className="step pl-15 pr-15 wrapper">
                        <div className="head-wrapper">
                            <div>
                                <Greeting />
                                <Stepper activeStep={activeStep} numOfSteps={policySteps[insuranceType]} />
                            </div>
                        </div>
                        {insuranceType === insuranceTypeEnum.Life ? <PhoneNumberField handleClickNext={handleClickNext} title={t("Can_you_share_your")} /> :
                            insuranceType === insuranceTypeEnum.Medical ? <PhoneNumberField handleClickNext={handleClickNext} title={t("Can_you_share_your")} medical /> :
                                insuranceType === insuranceTypeEnum.Motor ? <CarYearField handleClickNext={handleClickNext} /> :
                                    insuranceType === insuranceTypeEnum.Travel || insuranceType === insuranceTypeEnum.SMEMedical ||
                                        insuranceType === insuranceTypeEnum.InternationalMedical ? <NameField handleClickNext={handleClickNext} /> :
                                        insuranceType === insuranceTypeEnum.Home ? ownershipType === homeTypeEnum.Owner ? <HomeValue handleClickNext={handleClickNext} /> : <ContentValue handleClickNext={handleClickNext} /> :
                                            undefined}
                        <InsuranceProviders type={props.match.params?.type} />
                    </div>
            }
        </div>
    )
}
