import React from 'react'

export function RadioButton({ label, id, className, checked, value, icon, activeIcon, onChange, onClick }) {
    return (
        <div className={checked ? "insurance-type-card selected" : "insurance-type-card"} onClick={onClick}>
            <div className='insuranceTypeIcon'>
                {icon && <img className='image' src={!checked ? icon : activeIcon} alt="icon" width={29} height={29} />}
                <label htmlFor={id}>{label}</label>
            </div>
            <div>
                <input id={id} className={className} type="radio" name={id} label={id}
                    checked={checked} defaultValue={value} readOnly onChange={onChange} />
            </div>
        </div>
    )
}
export function CustomRadioButton({ label, id, name, checked, value, onChange, onClick }) {
    return (
        <div className="custom-radio" onClick={onClick} >
            <input id={id} type="radio" name={name}
                checked={checked} defaultValue={value} onChange={onChange} />
            <label htmlFor={id}>{label}</label>
        </div>
    )
}