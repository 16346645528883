import axios from "axios";
import { removeDefaultHeaders, addAxiosDefaultHeaders } from '../../helpers'
export class LeadsService {
    constructor(_baseUrl) {
        this._baseUrl = `${_baseUrl}/leads`
    }
    submitQuotation(values) {
        return axios.post(`${this._baseUrl}/v1/qe/submit`, values);
    }
    confirmQuote(values) {
        return axios.post(`${this._baseUrl}/v1/qe/confirm`, values);
    }
    payQuotation(values, accessToken) {
        removeDefaultHeaders(axios)
        const result = axios({
            method: "post",
            url: `${this._baseUrl}/v2/qe/pay`,
            data: values,
            headers: {
                Authorization: `auth ${accessToken}`,
            },
        })
        addAxiosDefaultHeaders(axios)
        return result
    }
    requestCall(id) {
        return axios.post(`${this._baseUrl}/v1/qe/${id}/request-call`);
    }
    trackTimeSpent(id, values) {
        return axios.post(`${this._baseUrl}/v1/qe/${id}/capture-page-duration`, values);
    }
    trackPageAction(id, values) {
        return axios.post(`${this._baseUrl}/v1/qe/${id}/capture-page-action`, values);
    }
    validateBirthDate(values) {
        return axios.post(`${this._baseUrl}/v1/qe/validate_birth_date`, values);
    }
}
