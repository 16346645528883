
import React, { Suspense } from 'react';
import Routes from "./routes";
import "./config/i18n";
import { etisalatHost } from './config/enums';
const EtisalatTheme = React.lazy(() => import('./Themes/EtisalatTheme.js'));
const ThemeSelector = ({ children }) => {
  const CHOSEN_THEME = etisalatHost;
  return (
    <>
      <Suspense fallback={<div>Loading.......</div>}>
        {CHOSEN_THEME && <EtisalatTheme />}
      </Suspense>
      {children}
    </>
  )
}
function App() {
  return (
    <ThemeSelector>
      <Routes />
    </ThemeSelector>
  );
}
export default App;
