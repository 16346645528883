import axios from "axios";
export class UtilityService {
    constructor(_baseUrl) {
        this._baseUrl = `${_baseUrl}/utility/v1/qe`
    }
    getBrands(params) {
        return axios.get(`${this._baseUrl}/motor_brands`, { params });
    }
    getCarModels(params) {
        return axios.get(`${this._baseUrl}/motor_models`, { params });
    }
    getMotorYears() {
        return axios.get(`${this._baseUrl}/motor_brands/model_years`);
    }
    getGovernorates() {
        return axios.get(`${this._baseUrl}/governorates`);
    }
    getCities(governorate_id) {
        return axios.get(`${this._baseUrl}/cities`, { params: { governorate_id } });
    }
}
