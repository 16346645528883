import React from 'react'
import { localStorageKeys, LocalStorageService } from '../../../application/services'
import { paymentMethodsEnum } from '../../../config/enums';

export function IFrame(props) {
    const instalment = LocalStorageService.get(localStorageKeys.paymentMethod) === paymentMethodsEnum.CARD_INSTALMENT || false
    return (
        <div className="container pt-0" id="page-wrapper" >
            <div className={instalment ? '' : 'wrapper'}>
                <iframe src={props.location && props.location.state && props.location.state.url ?
                    props.location.state.url : ''}
                    style={{ width: "100%", height: instalment ? "1100px" : "920px" }}
                    title="card"
                ></iframe>
            </div>
        </div >
    )
}