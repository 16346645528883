import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "../globalization/locales/en.json";
import ar from "../globalization/locales/arab.json";
import etisalatEn from "../globalization/locales/etisalatEn.json";
import etisalatAr from "../globalization/locales/etisalatArab.json";
import { etisalatHost } from "./enums";
const options = {
  order: ['querystring', 'path', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'subdomain'],
  lookupQuerystring: 'lang',
  // caches: []
}
const ArabicTrans = etisalatHost ? { ...ar, ...etisalatAr } : ar;

const englishTrans = etisalatHost ? { ...en, ...etisalatEn } : en;


export default i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: options,
    fallbackLng: "ar",
    supportedLngs: ["en", "ar"],
    resources: {
      ar: {
        translation: ArabicTrans
      },
      en: {
        translation: englishTrans
      },
    },
    interpolation: {
      format: function (value, format, lng) {

        if (typeof value === "number") return new Intl.NumberFormat().format(value);
        return value;
      }
    },
    react: {
      bindI18n: '',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: true,
    }
  })
export function changeCurrentLanguage(language) {
  i18n.changeLanguage(language);
  if (window.location.pathname === '/' && window.location.search.includes('lang')) {
    window.location.href = `${window.location.pathname}?${new URLSearchParams({ lang: language }).toString()}`
  }
  else {
    window.location.reload()
  }
}
export function getCurrentLanguage() {
  return i18n.language;
}
