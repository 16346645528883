import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomRadioButton } from '../../../components/ui';
import { LocalStorageService, MixpanelService, localStorageKeys } from '../../../application/services';
import { genderEnum } from '../../../config/enums';

export function GenderField(props) {
    const { t } = useTranslation();
    const [gender, setGender] = useState(LocalStorageService.get(localStorageKeys.userGender))
    const handleChangeGender = (e) => {
        setGender(e.target.value)
        MixpanelService.getInstance().selectGender({ gender: e.target.value === genderEnum.Female ? "female" : "male" })
        setTimeout(() => {
            props.handleClickNext()
            LocalStorageService.set(localStorageKeys.userGender, e.target.value);
        }, 500);
    }

    return (
        <div>
            <div className="heading mt-45">
                <p>{t("can_you_tell_me")}</p>
                <h3>{t("ask_for_gender")}</h3>
            </div>
            <div className="row">
                <div className="col-12 col-md">
                    <CustomRadioButton label={t("male")}
                        id="M"
                        value="M"
                        name="gender"
                        onChange={handleChangeGender}
                        onClick={handleChangeGender}
                        checked={gender === genderEnum.Male}
                    />
                </div>
                <div className="col-12 col-md">
                    <CustomRadioButton label={t("female")}
                        id="F"
                        value="F"
                        name="gender"
                        onChange={handleChangeGender}
                        onClick={handleChangeGender}
                        checked={gender === genderEnum.Female} />
                </div>
            </div>
        </div>
    )
}
