import React, { useState, useEffect, useRef, useCallback } from 'react'
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Spinner, PricingCard, Error, withErrorHandler } from '../../../components'
import { LocalStorageService, localStorageKeys, cookieStorageKeys, CookieStorageService, MixpanelService } from '../../../application/services';
import { ServiceProxy } from '../../../ServiceProxy';
import { getCurrentLanguage } from '../../../config/i18n';
import { insuranceTypeEnum, languageEnum, noRecommendationsTypes, etisalatHost, carTypeEnum, pageActions } from '../../../config/enums';
import { setQuotationData } from '../../../helpers'
import detect from "detect.js";
import { useLocation, useHistory } from 'react-router-dom';

function Results(props) {
    const [loading, setLoading] = useState(true)
    const [sendData, setSendData] = useState(false)
    const [invalidRoute, setInvalidRoute] = useState(null)
    const [comparisonPoints, setComparisonPoints] = useState([])
    const [quotationId, setQuotationId] = useState(null)
    const [packages, setPackages] = useState([])
    const [confirm, setConfirm] = useState(false)
    const [allowCheckout, setAllowCheckout] = useState(true)
    const [toggleSeeMore, setToggleSeeMore] = useState(false)
    const insuranceTypeId = Number(LocalStorageService.get(localStorageKeys.insuranceTypeID))
    const ref = useRef(null)
    const { t } = useTranslation();
    const [startTime, setStartTime] = useState(Date.now());
    const location = useLocation();
    const history = useHistory();
    const [startSession, setStartSession] = useState(new Date())

    const srollToBenfits = () => {
        var ua = detect.parse(navigator.userAgent);
        if (ua.os && ua.os.family) {
            if (!etisalatHost && (ua.os.family === 'Android' || ua.os.family === 'iOS')) {
                window.scrollTo(0, 110)
            }
            else {
                window.scrollTo(0, 0)
            }
        }
    }
    const setRef = useCallback((node) => {
        srollToBenfits()
        if (node) {
            getCurrentLanguage() === languageEnum.Arabic ? node.scrollTo(-190, 0) : node.scrollTo(190, 0);
        }
        ref.current = node;
    }, [])
    const handleToggleSeeMore = () => {
        if (toggleSeeMore === false) {
            trackSeeMore()
            MixpanelService.getInstance().clickSeeMore(props.location.state?.insurance_type)
        }
        else {
            MixpanelService.getInstance().clickSeeLess(props.location.state?.insurance_type)
        }
        setToggleSeeMore(!toggleSeeMore)
    }
    const getCopmarisons = () => {
        var params = {
            policy_type: insuranceTypeId,
        }
        if (insuranceTypeId === insuranceTypeEnum.MotorInsuranceId) {
            params = { ...params, is_electric: LocalStorageService.get(localStorageKeys.carType) === carTypeEnum.Electric ? true : false }
        }
        ServiceProxy.InsuranceService.loadComparison(params)
            .then(res => {
                if (res.data) {
                    setComparisonPoints(res.data)
                    setLoading(false)
                }
            })
            .catch(error => Sentry.captureException(error))

    }
    const loadingPackages = () => {
        const quotationData = setQuotationData()
        MixpanelService.getInstance().loadingPolicies(props.location.state?.insurance_type)
        ServiceProxy.LeadsService.submitQuotation(quotationData)
            .then(response => {
                // window.gtag('config', process.env.REACT_APP_GTAG_ID);
                // window.gtag('event', 'conversion', { 'send_to': `${process.env.REACT_APP_GTAG_ID}/TPQACKjW89kCEOzC1dQB` });
                if (response && response.data) {
                    MixpanelService.getInstance().loadingPoliciesStopped(props.location.state?.insurance_type)

                    const result = response.data
                    setQuotationId(result.lead_id)
                    setAllowCheckout(result.is_allow_checkout)
                    LocalStorageService.set(localStorageKeys.allowDiscountWithInstalment, result.is_instal_discount)
                    if (noRecommendationsTypes.includes(Number(LocalStorageService.get(localStorageKeys.insuranceTypeID))) || result.results.length === 0) {
                        setSendData(false)
                        props.history.replace({ pathname: `/success`, search: props.location.search })
                    }
                    else {
                        setPackages(result.results)
                        props.history.replace({
                            pathname: `/${LocalStorageService.get(localStorageKeys.insuranceType)}/loading-packages`,
                            state: {
                                ...props.location.state,
                                insurance_type: LocalStorageService.get(localStorageKeys.insuranceType),
                                options: result,
                                confirm: false
                            },
                            search: props.location.search
                        })
                        getCopmarisons()

                    }

                }
                else {
                    setSendData(false)
                }
            })
            .catch(function (error) {
                Sentry.captureException(error);
            });
    }
    useEffect(() => {
        if (props.location.state?.insurance_type !== props.match.params?.type) {
            setInvalidRoute(true)
        }
        else {
            MixpanelService.getInstance().qeLoadingPoliciesViewed(props.location.state?.insurance_type)
            if (noRecommendationsTypes.includes(Number(LocalStorageService.get(localStorageKeys.insuranceTypeID)))) {
                setSendData(true)
            }
            else {
                setLoading(true)
            }
            if (props.location.state?.options && props.location.state?.confirm === false) {
                setPackages(props.location.state.options.results)
                setQuotationId(props.location.state.options.lead_id)
                setAllowCheckout(props.location.state.options.is_allow_checkout)
                getCopmarisons()
            }
            else {
                loadingPackages()
            }
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps
    const sendTimeSpent = async (seconds) => {
        try {
            const data = {
                page_name: 'PACKAGES',
                seconds_spent: seconds,
                entry_time: startSession,
                exit_time: new Date()
            }
            await ServiceProxy.LeadsService.trackTimeSpent(quotationId, data)
                .then(() => { })
                .catch((error) => {
                    Sentry.captureException(error);
                });
        } catch (error) {
            Sentry.captureException(`Error sending time spent data:${error}`);
        }
    };
    const trackSeeMore = () => {
        ServiceProxy.LeadsService.trackPageAction(quotationId, { name: pageActions.SEE_MORE })
            .then(() => { })
            .catch((error) => {
                Sentry.captureException(error);
            });
    }
    useEffect(() => {
        if (loading === false) {
            const handleLocationChange = () => {
                const seconds = Math.floor((Date.now() - startTime) / 1000);
                if (seconds > 0) {
                    sendTimeSpent(seconds)
                    setStartTime(Date.now());
                    setStartSession(new Date())
                }
            };
            const unlisten = history.listen(() => {
                handleLocationChange();
            });
            const handleBeforeUnload = (event) => {
                const seconds = Math.floor((Date.now() - startTime) / 1000);
                if (seconds > 0) {
                    sendTimeSpent(seconds);
                    setStartTime(Date.now());
                    setStartSession(new Date())
                }
            };
            const handleVisibilityChange = () => {
                if (document.hidden) {
                    handleLocationChange();
                } else {
                    setStartSession(new Date())
                    setStartTime(Date.now());
                    setStartSession(new Date())
                }
            };
            const handlePageHide = () => {
                handleLocationChange();
            };
            const handleBlur = () => {
                handleLocationChange();
            };
            window.addEventListener('beforeunload', handleBeforeUnload);
            window.addEventListener('pagehide', handlePageHide);
            window.addEventListener('blur', handleBlur);
            document.addEventListener('visibilitychange', handleVisibilityChange);

            // Cleanup on component unmount
            return () => {
                unlisten();
                window.removeEventListener('beforeunload', handleBeforeUnload);
                window.removeEventListener('pagehide', handlePageHide);
                window.removeEventListener('blur', handleBlur);
                document.removeEventListener('visibilitychange', handleVisibilityChange);
            };
        }
        // eslint-disable-next-line
    }, [history, startTime, loading]);

    useEffect(() => {
        setStartTime(Date.now());
        setStartSession(new Date())
    }, [location]);
    const confirmPackage = (policyData, packageName) => {
        MixpanelService.getInstance().clickChoosePolicy(props.location.state?.insurance_type, { price: policyData.gross_premium_pound, plan_name: packageName })
        const data = {
            lead_id: quotationId,
            recommendation_id: policyData.id
        }
        if (quotationId && policyData && policyData.id) {
            setConfirm(true)
            ServiceProxy.LeadsService.confirmQuote(data)
                .then(response => {
                    if (response && response.status === 200) {
                        setConfirm(false)
                        LocalStorageService.set(localStorageKeys.policyPrice, policyData.gross_premium_pound)
                        LocalStorageService.set(localStorageKeys.discountedPolicyPrice, policyData.discounted_premium_pound)
                        LocalStorageService.set(localStorageKeys.monthlyPolicyPrice, policyData.month_premium_pound)
                        LocalStorageService.set(localStorageKeys.packageName, packageName)
                        LocalStorageService.set(localStorageKeys.quotationId, quotationId)
                        LocalStorageService.set(localStorageKeys.insuranceCompany, policyData.issuer_company_name)
                        LocalStorageService.set(localStorageKeys.recommendationId, policyData.id)
                        CookieStorageService.setCookie(cookieStorageKeys.token, response.data.token)
                        props.history.replace({
                            pathname: `/${LocalStorageService.get(localStorageKeys.insuranceType)}/loading-packages`,
                            state: {
                                ...props.location.state,
                                insurance_type: LocalStorageService.get(localStorageKeys.insuranceType),
                                confirm: true
                            },
                            search: props.location.search
                        })
                        if (insuranceTypeId === insuranceTypeEnum.SMEMedicalInsuranceId || etisalatHost) {
                            props.history.push({
                                pathname: `/thank-you`,
                                search: props.location.search,
                                state: {
                                    insurance_type: LocalStorageService.get(localStorageKeys.insuranceType),
                                    token: response.data.token,
                                    quotationID: quotationId,
                                }
                            })
                        }
                        else if (allowCheckout) {
                            props.history.push({
                                pathname: `/checkout`,
                                search: props.location.search,
                                state: {
                                    insurance_type: LocalStorageService.get(localStorageKeys.insuranceType),
                                    token: response.data.token,
                                    quotationID: quotationId,
                                }
                            })
                        }
                        else {
                            props.history.push({ pathname: `/success`, search: props.location.search, state: { insurance_type: LocalStorageService.get(localStorageKeys.insuranceType) } })
                        }
                    }
                    else {
                        setConfirm(false)
                    }
                })
                .catch(function (error) {
                    Sentry.captureException(error);
                });
        }
    }
    const requestCall = () => {
        setConfirm(true)
        MixpanelService.getInstance().clickRequestCall({ lead_id: quotationId })
        ServiceProxy.LeadsService.requestCall(quotationId)
            .then(() => {
                setConfirm(false)
                props.history.replace({ pathname: `/success`, state: { insurance_type: props.location.state?.insurance_type }, search: props.location.search })
                MixpanelService.getInstance().thankYouRequestCall()
            })
            .catch(error => {
                setConfirm(false)
                Sentry.captureException(error);
            });
    }
    return (
        <div className="container padding-for-phone pt-xl-0" id="page-wrapper">
            <div className="wrapper">
                {!navigator.onLine ?
                    <Error message={t("connection_error_message")} btnText={t("try_again_text")} onClick={() => window.location.reload()} /> :
                    loading ?
                        etisalatHost || insuranceTypeId === insuranceTypeEnum.InternationalMedicalId ? <Spinner /> : <Spinner hasImage heading={t("load_result")} /> :
                        sendData ? <Spinner /> :
                            confirm ? <Spinner /> :
                                invalidRoute ?
                                    <Error message={t("invalid_path_message")} btnText={t("home_btn")} autoRedirect /> :
                                    <>
                                        <div className="pricing-card" ref={setRef}>
                                            {(packages && comparisonPoints.length > 0) ? packages.map((item, index) => {
                                                return (
                                                    <PricingCard data={item}
                                                        confirmPackage={confirmPackage}
                                                        confirm={confirm}
                                                        removeAnnuallyLabel={LocalStorageService.get(localStorageKeys.insuranceTypeID) === insuranceTypeEnum.TravelInsuranceId}
                                                        key={index}
                                                        schema={comparisonPoints}
                                                        background={etisalatHost ? '#F5F5F5' : index === 1 ? "#F7E610" : index === 2 ? "#71DBD4" : ''}
                                                        packageName={index === 0 ? t("basic") : index === 1 ? t("standard") : index === 2 ? t("premium") : ''}
                                                        toggleSeeMore={toggleSeeMore}
                                                        handleToggleSeeMore={handleToggleSeeMore} />
                                                )
                                            }) : undefined}
                                        </div>
                                        <div className='request-call-card'>
                                            <button className='btn-call' onClick={requestCall}>{t('request_call')}</button>
                                            <p>{t('request_call-desc')}</p>
                                        </div>
                                    </>
                }
            </div>
        </div>
    )
}
export default withErrorHandler(Results, axios)