import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';
import { Spinner } from '../../../components/ui';
import { LocalStorageService, MixpanelService, localStorageKeys } from "../../../application/services";
import ExpandMore from '../../../assets/img/arrow_down.svg'
import ExpandMoreBlack from '../../../assets/img/arrow_down_black.svg'
import * as Sentry from '@sentry/react';
import { ServiceProxy } from '../../../ServiceProxy';
import { etisalatHost } from '../../../config/enums';

export function CityName(props) {
    const { t } = useTranslation();
    const [cityValue, setCityValue] = useState(null)
    const governorate = LocalStorageService.get(localStorageKeys.governorateID)
    const [cities, setCities] = useState([])
    const [loading, setLoading] = useState(true)
    const handleClickNext = () => {
        props.handleClickNext()
    }
    useEffect(() => {
        ServiceProxy.UtilityService.getCities(governorate)
            .then(response => {
                if (response.data) {
                    var cities = response.data
                    setCities(cities)
                    setLoading(false)
                    const city = LocalStorageService.get(localStorageKeys.city)
                    setCityValue(city ? cities.find(item => item.id === city) : null)
                }
                else {
                    setLoading(false)
                }
            })
            .catch(function (error) {
                setLoading(false)
                Sentry.captureException(error);
            });

    }, []);// eslint-disable-line react-hooks/exhaustive-deps
    return (
        <div>
            {loading ? <Spinner /> :
                <>
                    <div className="heading mt-45 mb-30">
                        <p>{props.title ? props.title : t("can_you_tell_me")}</p>
                        <h3>{t("city_name_label")}</h3>
                    </div>
                    <Autocomplete
                        options={cities || []}
                        getOptionLabel={(option) => option.name || null}
                        popupIcon={<img src={etisalatHost ? ExpandMoreBlack : ExpandMore} alt="expand" />}
                        onChange={(event, value) => {
                            setCityValue(value)
                            LocalStorageService.set(localStorageKeys.city, value.id);
                            MixpanelService.getInstance().selectCity({ city: value.name })
                            setTimeout(() => {
                                handleClickNext()
                            }, 500);
                        }}
                        onClose={(event, reason, value) => {
                            if (reason === 'selectOption') {
                                setTimeout(() => {
                                    handleClickNext()
                                }, 500);
                            }
                        }}
                        value={cityValue}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant={etisalatHost ? 'outlined' : 'standard'}
                                placeholder={t("city_name_placeholder")}
                                onFocus={() => MixpanelService.getInstance().clickCityField()}
                            />
                        )}
                        classes={{
                            option: etisalatHost ? 'menuItem' : null
                        }}
                    />
                </>
            }
        </div>
    )
}
